import { Container, Row, Col, Image } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import useScreenSize from "../hooks/useScreenSize";
import telroseBelgique from '../img/sva-Belgique.webp';
import telroseFrance from '../img/sva-France.webp';
import telroseLuxembourg from '../img/sva-Luxembourg.webp';
import telroseSuisse from '../img/sva-Suisse.webp';
import sprite from '../img/sprite.webp';
import telrose from '../img/telrose-home.webp';
import discrete from '../img/dicrete-home.webp';
import visioHome from '../img/visio-home.webp';

function Home() {

  const { width } = useScreenSize();

  return (
    <>
      <Helmet>
        <title>Téléphone rose sans attente | Appelez le 090340840</title>
        <meta name="description"
          content="Parlez avec des hôtesses sexy par téléphone. Service de téléphone rose disponible 24/7 pour des conversations intimes." />
        <link rel="canonical" href="https://telrose-telephone.be" />
        <link rel="preload" as="image" href="https://telrose-telephone.be/img/banner-home.webp" />
      </Helmet>
      <div style={{ background: "linear-gradient(180deg, rgba(0, 0, 0) 0%, rgba(255, 0, 0, 0.055) 50%, rgba(0, 0, 0) 100%), url(/img/banner-home.webp)" }} className="header-home text-center d-flex flex-column justify-content-center">
        <Container fluid="lg" className="header-home-container text-white">
          <h1 className="fw-bold display-5">Bienvenue sur telrose-telephone.be</h1>
          <h2 className="fw-bold mb-4">La référence de téléphone rose en Belgique</h2>
          {width < 769
            ?
            <a href="tel:090340840" aria-label="Appeler téléphone rose Belgique"><Image width={251} height={67} src={telroseBelgique} className="sva-header" rounded alt='numéro téléphone rose Belgique : 090340840' fluid /></a>
            :
            <Image width={251} height={67} src={telroseBelgique} className="sva-header" rounded alt='numéro téléphone rose Belgique : 090340840' fluid />
          }
        </Container>
      </div>
      <Container as={"h3"} fluid="lg" className="text-center mt-3 mt-lg-5 py-3 py-md-4 title-red-home fw-bold">
        Découvrez nos hôtesses et nos différents services de téléphone rose : appels directs ou en visio
      </Container>
      <Container as={"section"} fluid="lg" className="my-4 my-md-5">
        <article>
          <Row>
            <Col md={6} className="order-2 order-md-1 px-3 px-md-0 text-center">
              <Image width={660} height={351} src={telrose} fluid alt='femme brune sur un canapé au téléphone' />
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center text-center text-md-start order-1 order-md-2">
              <h2 className="fw-bold mb-4 display-5">Conversations torrides au bout du fil</h2>
              <p className="fw-normal">Vous cherchez à échapper à la routine et à ajouter un peu de piment à votre vie ? Nos hôtesses sont prêtes à vous offrir des moments inoubliables grâce à des conversations torrides qui éveilleront vos sens et satisferont vos désirs les plus profonds. Découvrez nos services et laissez-vous tenter par une expérience unique.</p>
            </Col>
            <Col md={{ span: 4, offset: 7 }} className="order-3 text-center">
              {width < 769
                ?
                <>
                  <a href="tel:090340840" className="d-block mt-5 mt-md-0" aria-label="Appeler téléphone rose Belgique">
                    <button className="button-home">Appel direct</button>
                  </a>
                  <span className="info-price"><small>Service 1,50 € / min + prix d'un appel</small></span>
                </>
                :
                <Image width={251} height={67} src={telroseBelgique} className='sva mb-5' rounded alt='numéro téléphone rose Belgique : 090340840' fluid />
              }
            </Col>
            <Col className='text-center order-4 mt-5 mt-md-3'>
              <h3 className='title-red-home p-2 mb-5 fw-bold'>Pour les appels hors de la Belgique</h3>
              <Row>
                {width < 769
                  ?
                  <>
                    <Col xs={12} lg={4} className='mb-4'>
                      <span className="d-block fw-bold mb-2 fs-5">Luxembourg</span>
                      <a href="tel:90528006" aria-label="Appeler téléphone rose Luxembourg">
                        <Image width={220} height={55} className='sva' src={telroseLuxembourg} alt="numéro téléphone rose Luxembourg : 90528006" rounded fluid />
                      </a>
                    </Col>
                    <Col xs={12} lg={4} className='mb-4'>
                      <span className="d-block fw-bold mb-2 fs-5">France</span>
                      <a href="tel:0895900400" aria-label="Appeler téléphone rose France">
                        <Image width={336} height={45} className='sva-france' src={telroseFrance} alt="numéro téléphone rose France : 0895900400" rounded fluid />
                      </a>
                    </Col>
                    <Col xs={12} lg={4} className='mb-4'>
                      <span className="d-block fw-bold mb-2 fs-5">Suisse</span>
                      <a href="tel:0901494494" aria-label="Appeler téléphone rose Suisse">
                        <Image width={220} height={55} className='sva' src={telroseSuisse} alt="numéro téléphone rose Suisse : 0901494494" rounded fluid />
                      </a>
                    </Col>
                  </>
                  :
                  <>
                    <Col xs={12} lg={4} className='mb-4 mb-lg-0'>
                      <span className="d-block fw-bold mb-2 fs-5">Luxembourg</span>
                      <Image width={220} height={55} className='sva' src={telroseLuxembourg} alt="numéro téléphone rose Luxembourg : 90528006" rounded fluid />
                    </Col>
                    <Col xs={12} lg={4} className='mb-4 mb-lg-0'>
                      <span className="d-block fw-bold mb-2 fs-5">France</span>
                      <Image width={336} height={45} className='sva-france' src={telroseFrance} alt="numéro téléphone rose France : 0895900400" rounded fluid />
                    </Col>
                    <Col xs={12} lg={4} className='mb-4 mb-lg-0'>
                      <span className="d-block fw-bold mb-2 fs-5">Suisse</span>
                      <Image width={220} height={55} className='sva' src={telroseSuisse} alt="numéro téléphone rose Suisse : 0901494494" rounded fluid />
                    </Col>
                  </>
                }
              </Row>
            </Col>
          </Row>
        </article>
      </Container>
      <hr />
      <Container as={"section"} fluid="lg" className="my-4 my-md-5">
        <article>
          <Row>
            <Col xs={12} md={6} className="d-flex flex-column justify-content-center text-center text-md-start">
              <h2 className="fw-bold mb-4 display-5">Découvrez nos hôtesses de téléphone rose</h2>
              <p className="fw-normal">Plongez dans un monde de sensualité et d'intimité avec nos hôtesses professionnelles, prêtes à vous offrir des moments inoubliables. Chaque hôtesse a été soigneusement sélectionnée pour son charme, son écoute et son expérience. Parcourez notre liste pour trouver celle qui saura répondre à vos désirs les plus secrets.</p>
            </Col>
            <Col md={6} className="px-3 px-md-0 text-center">
              <Image width={660} height={382} src={sprite} fluid alt="trois femmes en lingerie" />
            </Col>
            <Col md={{ span: 2, offset: 2 }} className="order-3 text-center">
              <NavLink to={"/hotesses"} className="d-block mt-5 mt-md-0" caseSensitive end>
                <button className="button-home">Nos hôtesses</button>
              </NavLink>
            </Col>
          </Row>
        </article>
      </Container>
      <hr />
      <Container as={"section"} fluid="lg" className="my-4 my-md-5">
        <article>
          <Row>
            <Col xs={12} md={6} className="order-2 order-md-1 px-3 px-md-0 text-center">
              <Image width={660} height={345} src={visioHome} fluid alt="une femme blonde en lingerie allongée en appel visio" />
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center text-center text-md-start order-1 order-md-2">
              <h2 className="fw-bold mb-4 display-5">Appels en visio</h2>
              <p className="fw-normal">Explorez une nouvelle dimension de plaisir et d'intimité avec nos appels visio au téléphone rose. Nous vous offrons la possibilité de vivre des moments érotiques intenses et authentiques grâce à des conversations vidéo personnalisées avec nos hôtesses expérimentées.</p>
            </Col>
            <Col md={{ span: 4, offset: 7 }} className="order-3 text-center">
              <NavLink to={"/visio"} className="d-block mt-5 mt-md-0" caseSensitive end>
                <button className="button-home">Visio</button>
              </NavLink>
            </Col>
          </Row>
        </article>
      </Container>
      <hr />
      <Container as={"section"} fluid="lg" className="my-4 my-md-5">
        <article>
          <Row>
            <Col className="d-flex flex-column justify-content-center text-center text-md-start">
              <h2 className="fw-bold mb-4 display-5">Discrétion Absolue</h2>
              <p className="fw-normal">Toutes nos conversations sont strictement confidentielles. Votre anonymat est notre priorité. Nous veillons à ce que rien sur votre facture ne puisse susciter des questions ou compromettre votre vie privée.Sur vos relevés téléphoniques ou bancaires, nos services apparaîtront sous une description générique qui ne révèle pas la nature de votre appel.</p>
            </Col>
            <Col xs={12} md={6} className="text-center px-3 px-md-0 text-center">
              <Image width={580} height={373} src={discrete} fluid alt="femme en lingerie masquée et discrète" />
            </Col>
          </Row>
        </article>
      </Container>
    </>
  )
}

export default Home;