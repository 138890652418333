import { Container, Row, Col, Image } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";
import useScreenSize from '../hooks/useScreenSize';
import useFilterByIdParam from "../hooks/useFilterByIdParam";
import hostessesVisioArray from "../constants/HostessesVisioArray";
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import visioBelgique from '../img/visio-Belgique.webp';
import visioSuisse from '../img/visio-Suisse.webp';

function Profil() {

  const hostesseProfil = useFilterByIdParam(hostessesVisioArray);

  const { width } = useScreenSize();

  const navigate = useNavigate();

  if (hostesseProfil) {
    return (
      <>
        <Helmet>
          {hostesseProfil.map(hostesse => (
            <Fragment key={hostesse.id}>
              <title key={hostesse.id}>{hostesse.metaTitle}</title>
              <meta name="description"
                content={hostesse.metaDescription} />
              <link rel="canonical" href={`https://telrose-telephone.be/profil-hotesse/${hostesse.id}/${hostesse.name}`} />
              <link rel="preload" as="image" href={`https://telrose-telephone.be/img/photos_large/${hostesse.photo}.webp`} />
            </Fragment>
          ))}
        </Helmet>
        <Container fluid="xl" className="my-3">
          <Row className="m-0 card-profil overflow-hidden rounded-3">
            {hostesseProfil.map(hostesse => (
              <Fragment key={hostesse.id}>
                <Col xs={12} lg={6} className="text-center p-0">
                  <Image width={1024} height={1024} className="photo-profil" src={`/img/photos_visio/photos_large/${hostesse.photo}.webp`} alt={hostesse.alt} fluid />
                </Col>
                <Col lg={6} className="p-0 d-flex flex-column justify-content-between">
                  <h1 className="mt-3 mt-lg-0 mb-3 p-1 p-md-2 p-lg-3 card-title-custom text-center">{hostesse.name}</h1>
                  <div className="px-1 px-lg-3 mb-2 text-center text-lg-start">
                    {hostesse.description}
                  </div>
                  <div className='text-center mb-5'>
                    {width < 769
                      ?
                      <>
                        <a href="tel:090404500" className="d-block">
                          <button className="button-home"><FontAwesomeIcon icon={faVideo} className='me-2' />Appel visio</button>
                        </a>
                        <span className="info-price"><small>Service 2,00 € / min + prix d'un appel</small></span>
                      </>
                      :
                      <div className="d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={faVideo} className='fs-3 p-2 me-3 icon-video' />
                        <Image width={251} height={67} src={visioBelgique} className='sva' rounded alt="numéro d'appel en visio Belgique : 090404500" fluid />
                      </div>
                    }
                  </div>
                </Col>
                <Col className='text-center'>
                  <h2 className='card-title-custom p-2 mb-4 fs-3 fw-bold'>Appel en visio disponible depuis la Suisse</h2>
                  <Row className='mb-3'>
                    {width < 769
                      ?
                      <>
                        <Col xs={12} className='mb-4 text-center'>
                          <span className="d-block fw-bold mb-2 fs-5">Suisse</span>
                          <div className="d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faVideo} className='fs-3 p-2 me-3 icon-video' />
                            <a href="tel:0901989989">
                              <Image width={251} height={67} className='sva' src={visioSuisse} rounded alt="numéro d'appel en visio Suisse : 0901989989" fluid />
                            </a>
                          </div>
                        </Col>
                      </>
                      :
                      <>
                        <Col xs={12} className='mb-4 text-center'>
                          <span className="d-block fw-bold mb-2 fs-5">Suisse</span>
                          <div className="d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faVideo} className='fs-3 p-2 me-3 icon-video' />
                            <Image width={251} height={67} className='sva' src={visioSuisse} rounded alt="numéro d'appel en visio Suisse : 0901989989" fluid />
                          </div>
                        </Col>
                      </>
                    }
                  </Row>
                </Col>
              </Fragment>
            ))}
          </Row>
          <div className="text-center mt-4 mb-5">
            <button onClick={() => navigate(-1)} className="button-home  text-center">Retour</button>
          </div>
        </Container>
      </>
    )
  }
  return (
    <Navigate to="/404" />
  )
}

export default Profil;