import { NavLink } from "react-router-dom";

function ErrorPage() {
  return (
    <div className="text-center">
      <h1 className="display-5 fw-bold mb-5">Page Not Found</h1>
      <NavLink to="/"><button className="button-home button-404">Retour à l'accueil</button></NavLink>
    </div>
  )
}

export default ErrorPage;