const hostessesVisioArray = [
  {
    id: 1,
    name: "Camille",
    photo: "image(1)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Une séductrice innée, elle sait captiver votre attention dès le premier regard. Son sourire envoûtant et son regard mystérieux vous feront chavirer instantanément.",
    metaTitle: "Hôtesse de Téléphone Rose | Conversations Sensuelles & Discrètes avec Cammille au 090340840",
    metaDescription: "Découvrez une expérience sensuelle et personnalisée avec notre hôtesse de téléphone rose. Conversations envoûtantes, plaisir garanti, confidentialité totale.",
    description:
      <>
        <p>Je vous invite à découvrir un univers de sensualité et de complicité. Mon charme et mon écoute attentive transforment chaque échange en un moment d’intimité inoubliable. Explorez vos fantasmes en toute liberté à travers des visios torrides et passionnés.</p>
        <p>Passionnée par les échanges en ligne, je m'adapte à vos désirs les plus secrets. Avec moi, chaque conversation est une aventure sensuelle où vos fantasmes prennent vie. Mon allure provocante et mon sourire envoûtant vous promettent des moments d'excitation intenses et personnalisés.</p>
        <p>Prête à éveiller vos sens, je vous offre des moments de plaisir exclusifs en visio. Entrez dans mon univers où chaque regard et chaque mot sont une promesse de sensations fortes. Laissez-vous emporter par l’extase d’échanges torrides et enrichissants, où vos désirs sont comblés.</p>
      </>
  },
  {
    id: 2,
    name: "Lola",
    photo: "image(2)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Lola est une maîtresse des fantasmes et des plaisirs visuels. Avec une voix envoûtante et un regard captivant, elle sait comment éveiller vos désirs les plus profonds.",
    metaTitle: "Hôtesse de Téléphone Rose - Conversation Sensuelle et Confidentielle avec Sarah au 090340840",
    metaDescription: "Découvrez des conversations envoûtantes avec notre hôtesse de téléphone rose. Évasion, fantasmes et plaisir dans un cadre confidentiel et respectueux.",
    description:
      <>
        <p>Passionnée par le flirt et l'art de la séduction, je vous invite à une expérience en visio pleine de chaleur et d'authenticité. Mes échanges sont empreints de sensualité et de complicité, pour éveiller vos désirs les plus secrets.</p>
        <p>Dans nos moments ensemble, je crée une atmosphère intime et envoûtante où chaque geste et chaque mot sont soigneusement choisis pour vous plonger dans un univers de plaisir. Vous serez captivé par mon charme et mon écoute attentive.</p>
        <p>Prête à explorer vos fantasmes avec vous, je vous offre une aventure sensorielle unique. Soyez assuré que chaque interaction sera un voyage sensuel inoubliable, guidé par mon désir de vous satisfaire et de vous faire vivre des instants de pure volupté.</p>
      </>
  },
  {
    id: 3,
    name: "Élise",
    photo: "image(3)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Élise est une hôtesse de téléphone rose en visio, connue pour sa voix douce et sensuelle, ainsi que son sourire enchanteur.",
    metaTitle: "Éveillez vos Sens avec Charlotte : Hôtesse de Téléphone Rose | 090340840",
    metaDescription: "Découvrez Charlotte, votre hôtesse de téléphone rose. Conversations envoûtantes, moments sur mesure et discrétion totale. Évadez-vous et vivez des instants mémorables.",
    description:
      <>
        <p>Découvrez une expérience sensorielle inoubliable avec moi, votre hôtesse de téléphone rose. Je vous invite à un voyage captivant où chaque échange en visio devient une aventure intime et personnalisée. Avec un charme irrésistible et une écoute attentive, je transforme chaque instant en une évasion sensuelle et pleine de passion.</p>
        <p>Mon objectif est de vous offrir une connexion authentique et un moment d'évasion où vous pouvez explorer vos désirs en toute liberté. Chaque interaction est soigneusement conçue pour répondre à vos fantasmes et pour créer une atmosphère de complicité et de plaisir mutuel. Votre satisfaction est ma priorité absolue.</p>
        <p>Rejoignez-moi pour une expérience où vos souhaits deviennent réalité et où chaque regard, chaque sourire est une promesse de moments inoubliables. Laissez-moi vous emmener dans un univers où la sensualité et la délectation sont à l'honneur, pour des échanges intenses et mémorables.</p>
      </>
  },
  {
    id: 4,
    name: "Pauline",
    photo: "image(4)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Maîtresse dans l’art de la séduction, elle vous surprendra par son imagination débordante et ses propositions audacieuses.",
    metaTitle: "Moments Intimes avec Pauline : Téléphone Rose au 090340840",
    metaDescription: "Découvrez Pauline, votre hôtesse de téléphone rose. Conversations passionnées, écoute attentive, et moments sur mesure pour une expérience intime et inoubliable.",
    description:
      <>
        <p>Découvrez un monde de passion et de sensualité avec moi, votre hôtesse de téléphone rose, disponible pour des échanges torrides en visio. Je suis là pour éveiller vos désirs les plus secrets et satisfaire vos fantasmes dans une atmosphère intime et envoûtante. Laissez-vous emporter par des conversations excitantes et pleines de promesses.</p>
        <p>Chaque moment passé ensemble sera une exploration de vos envies les plus profondes, dans un cadre privé et sécurisé. Avec moi, vous trouverez une écoute attentive et une réponse à vos désirs, quel que soit le fantasme que vous souhaitez partager. Osez l’aventure et laissez-vous séduire.</p>
        <p>Je suis ici pour vous offrir une expérience inoubliable, où chaque échange est une danse entre plaisir et complicité. Rejoignez-moi pour des instants de plaisir intensément personnel et découvrez un nouveau niveau de satisfaction. Votre fantasme devient réalité, ici et maintenant.</p>
      </>
  },
  {
    id: 5,
    name: "Marine",
    photo: "image(5)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle excelle dans l’art de jouer avec les fantasmes, offrant des moments de plaisir inédits et personnalisés.",
    metaTitle: "Hôtesse de Téléphone Rose : Expérience Sensuelle avec Marine au 090340840",
    metaDescription: "Découvrez Marine, votre hôtesse de téléphone rose, pour des conversations envoûtantes et personnalisées. Moments chaleureux, confidentiels et sur mesure vous attendent.",
    description:
      <>
        <p>Passionnée et audacieuse, je suis votre compagne idéale pour des échanges torrides en visio. Mon regard envoûtant et ma voix sensuelle vous transporteront dans un univers de désir et de plaisir, où chaque mot et chaque geste sont conçus pour éveiller vos fantasmes les plus secrets.</p>
        <p>Que vous cherchiez à explorer vos fantasmes ou simplement à vous détendre après une longue journée, je suis là pour vous offrir une expérience unique et personnalisée. Avec moi, chaque moment devient une aventure excitante, pleine de passion et de complicité.</p>
        <p>Prêt à franchir le seuil du désir et à vivre des instants de pure électricité? Osez me découvrir, et laissez-vous guider par mon charme irrésistible. Ensemble, nous créerons des souvenirs inoubliables qui éveilleront vos sens et feront battre votre cœur plus fort.</p>
      </>
  },
]

export default hostessesVisioArray;