import { useState, useEffect } from "react";

const useWindowPosition = () => {
  const [WindowPosition, setWindowPosition] = useState({
    positionX: window.scrollX,
    positionY: window.scrollY
  })

  useEffect(() => {
    const handlePosition = () => {
      setWindowPosition({
        positionX: window.scrollX,
        positionY: window.scrollY
      })
    }

    window.addEventListener('scroll', handlePosition);

    return () => {
      window.removeEventListener('scroll', handlePosition);
    }
  }, []);

  return WindowPosition;
}

export default useWindowPosition;