import { Navbar, Nav, Offcanvas, Container, Image } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { useState } from 'react';
import useScreenSize from '../hooks/useScreenSize';
import useWindowPosition from '../hooks/useWindowPosition';
import logo from '../img/logo.webp';
import sva from '../img/sva-Belgique.webp';

function Header() {

  const { pathname } = useLocation();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
  }

  const { width } = useScreenSize();

  const { positionY } = useWindowPosition();

  return (
    <header className='sticky-top'>
      <Navbar key={"lg"} expand={"lg"} className='py-1'>
        <Container fluid="lg">
          <NavLink to={"/"}><Image width={80} height={80} className='logo' src={logo} alt='logo telrose-telephone.be' fluid /></NavLink>
          {width < 992 &&
            <>
              {width < 769
                ?
                <a href="tel:090340840" aria-label="Appeler téléphone rose Belgique"><Image width={251} height={67} src={sva} className={`sva-navbar ${positionY > window.innerHeight / 2.5 ? "d-inline" : "d-none"}`} rounded alt='numéro téléphone rose Belgique : 090340840' fluid /></a>
                :
                <Image width={251} height={67} src={sva} className={`sva-navbar ${positionY > window.innerHeight / 2.5 ? "d-inline" : "d-none"}`} rounded alt='numéro téléphone rose Belgique : 090340840' fluid />
              }
            </>
          }
          <Navbar.Toggle className='pe-0' onClick={handleShow} aria-controls={`offcanvasNavbar-expand-sm`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            show={show}
            onHide={handleClose}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="start"
            className="bg-black"
          >
            <Offcanvas.Header closeButton closeVariant='white' className='pt-2'>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`} className='text-white'>
                <NavLink to={"/"} target='_blank' onClick={handleClose} caseSensitive end>
                  <Image width={80} height={80} className='logo me-4' src={logo} alt='logo telrose-telephone.be' fluid />
                </NavLink>
                <a href="tel:090340840" aria-label="Appeler téléphone rose Belgique"><Image width={251} height={67} src={sva} className='sva-navbar' rounded alt='numéro téléphone rose Belgique : 090340840' fluid /></a>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-start flex-grow-1 pe-3">
                <NavLink onClick={handleClose} className="navLink mb-2 mb-sm-0 mx-lg-5 text-white rounded-1" to={"/"}>Accueil</NavLink>
                <NavLink onClick={handleClose} className="navLink mb-2 mb-sm-0 me-5 text-white rounded-1" to={"/hotesses"}>Hôtesses</NavLink>
                <NavLink onClick={handleClose} className="navLink text-white rounded-1" to={"/visio"}>Visio</NavLink>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          {width > 991 &&
            <Image width={251} height={67} src={sva} className='sva-navbar py-1' rounded alt='numéro téléphone rose Belgique : 090340840' fluid />
          }
        </Container>
      </Navbar>
    </header>
  )
}

export default Header;