import './App.css';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import Main from './components/Main';
import ButtonToScroll from './components/ButtonToScroll';
import Footer from './components/Footer';
import useWindowPosition from './hooks/useWindowPosition';

function App() {

  const { pathname } = useLocation();

  const { positionY } = useWindowPosition();

  const TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID;

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: TRACKER_ID,
        gtagOptions: {
          send_page_view: false
        },
      }
    ]);
    ReactGA.send({ hitType: "pageview", page: pathname, title: pathname === "/" ? "Accueil" : pathname.charAt(1).toUpperCase() + pathname.substring(2) });
  }, [pathname])

  return (
    <HelmetProvider>
      <Header />
      <Main />
      {positionY > 50 &&
        <ButtonToScroll />
      }
      <Footer />
    </HelmetProvider>
  );
}

export default App;
