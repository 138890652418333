
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import useScreenSize from "../hooks/useScreenSize";
import Home from "../pages/Home";
import Hostesses from "../pages/Hostesses";
import HostesseProfil from "../pages/hostesseProfil";
import HostesseVisioProfil from "../pages/hostesseVisioProfil";
import Visio from "../pages/Visio";
import LegalNotice from "../pages/LegalNotice";
import ErrorPage from '../pages/404';

function Main() {

  const { pathname } = useLocation();

  const { height } = useScreenSize();

  useEffect(() => {
    if (document.getElementById("main").offsetHeight < height) {
      document.getElementById("root").style.height = "100vh";
    }
    else {
      document.getElementById("root").style.height = "auto";
    }
    window.scrollTo({ top: 0, left: 0, behavior: "instant" })
  }, [pathname])

  return (
    <main id="main">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/accueil" element={<Home />} />
        <Route path="/hotesses" element={<Hostesses />} />
        <Route path="/visio" element={<Visio />} />
        <Route path="profil-hotesse/:id/:name" element={<HostesseProfil />} />
        <Route path="profil-hotesse-visio/:id/:name" element={<HostesseVisioProfil />} />
        <Route path="mentions-legales" element={<LegalNotice />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="404" element={<ErrorPage />} />
      </Routes>
    </main>
  )
}

export default Main;