import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
// import { useLocation } from "react-router-dom";
// import ReactGA from 'react-ga4';

function Footer() {

  // const { pathname } = useLocation();

  // function scrollToTop() {
  //   setScrollToHostesses(false);
  //   window.scrollTo(0, 0);
  // }
  // function scrollToHostesses() {
  //   setScrollToHostesses(true);
  // }
  // function scrollToSection() {
  //   window.scrollTo(0, document.getElementById('section-hostesses').getBoundingClientRect().y + window.scrollY - document.getElementById("header").offsetHeight);
  // }

  // function handleClick({ category, action }) {
  //   ReactGA.event({
  //     category: category,
  //     action: action
  //   });
  // }

  return (
    <footer className='text-white text-center p-3'>
      <Container>
        <div>
          <NavLink className="text-white text-decoration-none px-1" to={"/accueil"}>Accueil</NavLink>
          <NavLink className="text-white text-decoration-none px-1" to={"/hotesses"}>Hôtesses</NavLink>
          <NavLink className="text-white text-decoration-none px-1" to={"/visio"}>Visio</NavLink>
        </div>
        {/* <div>
          <span>Site partenaire : <a className="link" href="https://telrose-duo.fr/" target="_blank" rel="noreferrer"
            onClick={() => handleClick({ category: "Link to telrose-duo.fr - Footer", action: "Click to link" })}>Telrose-duo.fr</a></span>
        </div> */}
        <div>
          <small><NavLink className="text-white pe-1" to={"/mentions-legales"} caseSensitive end>Mentions légales</NavLink></small>
        </div>
        <small className="text-white">Copyright &copy; 2024 telrose-telephone.be. Tous droits réservés.</small>
      </Container>
    </footer>
  )
}

export default Footer;