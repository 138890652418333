import hostesses from '../constants/HostessesArray';
import { Helmet } from "react-helmet-async";
import { NavLink } from 'react-router-dom';
import { Container, Image, Card, Row, Col } from "react-bootstrap";

function Hostesses() {
  return (
    <>
      <Helmet>
        <title>Les Hôtesses de Téléphone Rose les Plus Sensuelles au 090340840</title>
        <meta name="description"
          content="Rencontrez les hôtesses de téléphone rose les plus sensuelles et laissez-vous transporter par leur voix envoûtante. Chaque hôtesse de notre liste est une experte en art de la conversation érotique." />
        <link rel="canonical" href="https://telrose-telephone.be/hotesses" />
        <link rel="preload" as="image" href="https://telrose-telephone.be/img/banner-hostesses.webp" />
      </Helmet>
      <div style={{ background: "linear-gradient(180deg, rgba(255, 0, 0, 0.055) 0%, rgba(0, 0, 0) 100%), url(/img/banner-hostesses.webp)" }} className="header-hostesses text-center d-flex flex-column justify-content-center">
        <Container fluid="lg" className="header-home-container text-white">
          <h1 className="fw-bold display-5">Nos hôtesses de téléphone rose</h1>
        </Container>
      </div>
      <Container fluid="lg" className="container-hostesses mt-3 mt-lg-5 text-center">
        <h2 className="title-red-hostesses fs-3 py-3 py-md-4 mb-5 fw-bold">Bienvenue sur notre page dédiée aux hôtesses de téléphone rose disponibles 24/24</h2>
        <p>Nos hôtesses expérimentées sont prêtes à répondre à vos désirs les plus intimes, vous offrant des moments sur mesure et une écoute attentive. Sélectionnez l’hôtesse idéale pour satisfaire toutes vos envies, jour et nuit. La confidentialité et le respect sont garantis pour chaque appel, vous permettant de vous évader en toute sérénité.</p>
        <Row as={"ul"} xs={2} sm={3} lg={4} xxl={5} className="mt-5">
          {hostesses.map(hostesse => (
            <Col key={hostesse.id} as={"li"} className="mb-3">
              <NavLink className={"text-decoration-none"} to={`/profil-hotesse/${hostesse.id}/${hostesse.name}`}>
                <Card className="h-100 rounded-3" text="white">
                  <Card.Img width={305} height={305} className="rounded-top-3" variant="top" src={`/img/photos_telrose/photos_small/${hostesse.photo}.webp`} alt={hostesse.alt} />
                  <Card.Body>
                    <Card.Title className="mb-3 p-1 fw-bold card-title-custom">{hostesse.name}</Card.Title>
                    <Card.Text>
                      {hostesse.cardDescription}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </NavLink>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}

export default Hostesses;