import { Container, Row, Col, Image } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Navigate, useNavigate } from "react-router-dom";
import useScreenSize from '../hooks/useScreenSize';
import useFilterByIdParam from "../hooks/useFilterByIdParam";
import hostessesArray from "../constants/HostessesArray";
import { Fragment } from 'react';
import telroseBelgique from '../img/sva-Belgique.webp';
import telroseFrance from '../img/sva-France.webp';
import telroseLuxembourg from '../img/sva-Luxembourg.webp';
import telroseSuisse from '../img/sva-Suisse.webp';

function Profil() {

  const hostesseProfil = useFilterByIdParam(hostessesArray);

  const { width } = useScreenSize();

  const navigate = useNavigate();

  if (hostesseProfil) {
    return (
      <>
        <Helmet>
          {hostesseProfil.map(hostesse => (
            <Fragment key={hostesse.id}>
              <title key={hostesse.id}>{hostesse.metaTitle}</title>
              <meta name="description"
                content={hostesse.metaDescription} />
              <link rel="canonical" href={`https://telrose-telephone.be/profil-hotesse/${hostesse.id}/${hostesse.name}`} />
              <link rel="preload" as="image" href={`https://telrose-telephone.be/img/photos_large/${hostesse.photo}.webp`} />
            </Fragment>
          ))}
        </Helmet>
        <Container fluid="xl" className="my-3">
          <Row className="m-0 card-profil overflow-hidden rounded-3">
            {hostesseProfil.map(hostesse => (
              <Fragment key={hostesse.id}>
                <Col xs={12} lg={6} className="text-center p-0">
                  <Image width={1024} height={1024} className="photo-profil" src={`/img/photos_telrose/photos_large/${hostesse.photo}.webp`} alt={hostesse.alt} fluid />
                </Col>
                <Col lg={6} className="p-0 d-flex flex-column justify-content-between">
                  <h1 className="mt-3 mt-lg-0 mb-3 p-1 p-md-2 p-lg-3 card-title-custom text-center">{hostesse.name}</h1>
                  <div className="px-1 px-lg-3 mb-2 text-center text-lg-start">
                    {hostesse.description}
                  </div>
                  <div className='text-center mb-5'>
                    {width < 769
                      ?
                      <>
                        <a href="tel:090340840" className="d-block">
                          <button className="button-home">Appel direct</button>
                        </a>
                        <span className="info-price"><small>Service 1,50 € / min + prix d'un appel</small></span>
                      </>
                      :
                      <Image width={251} height={67} src={telroseBelgique} className='sva' rounded alt='numéro téléphone rose Belgique : 090340840' fluid />
                    }
                  </div>
                </Col>
                <Col className='text-center'>
                  <h2 className='card-title-custom p-2 mb-4 fs-3 fw-bold'>Pour les appels hors de la Belgique</h2>
                  <Row className='mb-3'>
                    {width < 769
                      ?
                      <>
                        <Col xs={12} lg={4} className='mb-4'>
                          <span className="d-block fw-bold mb-2 fs-5">Luxembourg</span>
                          <a href="tel:90528006">
                            <Image width={220} height={55} className='sva' src={telroseLuxembourg} rounded alt="numéro téléphone rose Luxembourg : 90528006" fluid />
                          </a>
                        </Col>
                        <Col xs={12} lg={4} className='mb-4'>
                          <span className="d-block fw-bold mb-2 fs-5">France</span>
                          <a href="tel:0895900400">
                            <Image width={336} height={45} className='sva-france' src={telroseFrance} rounded alt="numéro téléphone rose France : 0895900400" fluid />
                          </a>
                        </Col>
                        <Col xs={12} lg={4} className='mb-4'>
                          <span className="d-block fw-bold mb-2 fs-5">Suisse</span>
                          <a href="tel:0901494494">
                            <Image width={220} height={55} className='sva' src={telroseSuisse} rounded alt="numéro téléphone rose : 0901494494" fluid />
                          </a>
                        </Col>
                      </>
                      :
                      <>
                        <Col xs={12} lg={4} className='mb-4'>
                          <span className="d-block fw-bold mb-2 fs-5">Luxembourg</span>
                          <Image width={220} height={55} className='sva' src={telroseLuxembourg} rounded alt="numéro téléphone rose Luxembourg : 90528006" fluid />
                        </Col>
                        <Col xs={12} lg={4} className='mb-4'>
                          <span className="d-block fw-bold mb-2 fs-5">France</span>
                          <Image width={336} height={45} className='sva-france' src={telroseFrance} rounded alt="numéro téléphone rose France : 0895900400" fluid />
                        </Col>
                        <Col xs={12} lg={4} className='mb-4'>
                          <span className="d-block fw-bold mb-2 fs-5">Suisse</span>
                          <Image width={220} height={55} className='sva' src={telroseSuisse} rounded alt="numéro téléphone rose Suisse : 0901494494" fluid />
                        </Col>
                      </>
                    }
                  </Row>
                </Col>
              </Fragment>
            ))}
          </Row>
          <div className="text-center mt-4 mb-5">
            <button onClick={() => navigate(-1)} className="button-home  text-center">Retour</button>
          </div>
        </Container>
      </>
    )
  }
  return (
    <Navigate to="/404" />
  )
}

export default Profil;