import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
function ButtonToScroll() {

  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  
  return (
    <>
    <button id="button-to-scroll-up" onClick={() => scrollToTop()}><FontAwesomeIcon id="icon-up" icon={faChevronUp} /></button>
    </>
  )
}

export default ButtonToScroll;