const hostessesArray = [
  {
    id: 1,
    name: "Camille",
    photo: "image(1)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle n’a pas peur d’explorer les limites et de s’aventurer dans des conversations osées et excitantes.",
    metaTitle: "Hôtesse de Téléphone Rose | Conversations Sensuelles & Discrètes avec Cammille au 090340840",
    metaDescription: "Découvrez une expérience sensuelle et personnalisée avec notre hôtesse de téléphone rose. Conversations envoûtantes, plaisir garanti, confidentialité totale.",
    description:
      <>
        <p>Je suis Camille, votre hôtesse dédiée, prête à vous emmener dans un voyage unique où vos désirs deviennent réalité.</p>
        <p>Avec une voix douce et envoûtante, je suis là pour éveiller vos sens, vous offrir des moments de détente absolue et vous guider dans l'exploration de vos fantasmes les plus secrets.</p>
        <p>Que vous cherchiez une conversation chaleureuse ou une immersion plus intense dans le monde du plaisir, je suis à l’écoute pour satisfaire toutes vos attentes.</p>
        <p>Avec moi, chaque appel est une promesse d’évasion et de satisfaction, où vous êtes le héros de votre propre récit sensuel. Venez découvrir l’art de la conversation intime comme vous ne l’avez jamais vécu auparavant.</p>
      </>
  },
  {
    id: 2,
    name: "Sarah",
    photo: "image(2)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle sait écouter et adapter ses paroles pour répondre précisément à vos attentes et à vos envies du moment.",
    metaTitle: "Hôtesse de Téléphone Rose - Conversation Sensuelle et Confidentielle avec Sarah au 090340840",
    metaDescription: "Découvrez des conversations envoûtantes avec notre hôtesse de téléphone rose. Évasion, fantasmes et plaisir dans un cadre confidentiel et respectueux.",
    description:
      <>
        <p>Je suis Sarah, votre hôtesse de téléphone rose dévouée, prête à vous offrir des moments de plaisir et de complicité.</p>
        <p>Avec une voix douce et enivrante, je suis là pour écouter, comprendre et répondre à toutes vos fantasmes les plus secrets.</p>
        <p>Je fais en sorte que chaque échange soit à la fois excitant et réconfortant, vous permettant de vous évader de votre quotidien et de vivre des moments de plaisir purement personnel.</p>
        <p>Que vous ayez envie de confidences sensuelles ou de discussions plus osées, je suis à votre disposition pour rendre chaque appel inoubliable.</p>
      </>
  },
  {
    id: 3,
    name: "Charlotte",
    photo: "image(3)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Avec une imagination débordante, elle aime inventer des scénarios érotiques variés, allant des plus romantiques aux plus torrides.",
    metaTitle: "Éveillez vos Sens avec Charlotte : Hôtesse de Téléphone Rose | 090340840",
    metaDescription: "Découvrez Charlotte, votre hôtesse de téléphone rose. Conversations envoûtantes, moments sur mesure et discrétion totale. Évadez-vous et vivez des instants mémorables.",
    description:
      <>
        <p>Bonjour ! Je suis Charlotte, une hôtesse de téléphone rose passionnée par les échanges chaleureux et les conversations intimes.</p>
        <p>je suis ici pour vous offrir des moments de détente et de plaisir, où chaque mot compte et chaque échange est unique. Que vous soyez à la recherche d’une conversation légère ou d’un dialogue plus profond, je suis là pour vous écouter et vous faire vivre des instants agréables.</p>
        <p>Lorsque vous choisissez de passer un moment avec moi, vous pouvez être assuré de trouver un espace de liberté où vous pouvez vous exprimer sans retenue. Je suis là pour partager des instants de complicité et de détente, avec discrétion et respect.</p>
        <p>N’hésitez pas à me contacter pour un moment de plaisir et de réconfort, où chaque échange est une nouvelle aventure pleine de surprises.</p>
      </>
  },
  {
    id: 4,
    name: "Pauline",
    photo: "image(4)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Sa voix douce et rassurante vous mettra immédiatement à l’aise, créant une connexion intime et chaleureuse.",
    metaTitle: "Moments Intimes avec Pauline : Téléphone Rose au 090340840",
    metaDescription: "Découvrez Pauline, votre hôtesse de téléphone rose. Conversations passionnées, écoute attentive, et moments sur mesure pour une expérience intime et inoubliable.",
    description:
      <>
        <p>Je suis Pauline, votre hôtesse de téléphone rose, prête à vous offrir une écoute attentive et des moments d’évasion sur mesure. Avec une voix douce et chaleureuse, je suis ici pour transformer vos fantasmes en réalité et pour vous guider à travers des discussions passionnantes et intrigantes.</p>
        <p>Mon objectif est de faire en sorte que chaque interaction soit aussi mémorable que satisfaisante, en utilisant mon imagination pour répondre à vos attentes les plus secrètes.</p>
        <p>Si vous cherchez une compagnie captivante et une conversation stimulante, n’hésitez pas à me contacter. Je suis impatiente de vous connaître et de partager avec vous des moments intimes et exaltants.</p>
      </>
  },
  {
    id: 5,
    name: "Marine",
    photo: "image(5)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle sait utiliser sa voix et ses mots pour séduire et envoûter. Sa capacité à créer une ambiance intime et attrayante est essentielle.",
    metaTitle: "Hôtesse de Téléphone Rose : Expérience Sensuelle avec Marine au 090340840",
    metaDescription: "Découvrez Marine, votre hôtesse de téléphone rose, pour des conversations envoûtantes et personnalisées. Moments chaleureux, confidentiels et sur mesure vous attendent.",
    description:
      <>
        <p>Bienvenue sur mon profil ! Je suis Marine, une hôtesse de téléphone rose passionnée par les échanges authentiques et l'art de la conversation envoûtante. Avec une voix douce et un sens inné de la complicité, je suis ici pour transformer chaque appel en un moment de plaisir et de découverte.</p>
        <p>Je m'engage à vous offrir une expérience personnalisée et inoubliable, en tenant compte de vos désirs et préférences. Que vous cherchiez à explorer vos fantasmes les plus secrets ou simplement à passer un moment agréable en bonne compagnie, je suis là pour répondre à vos attentes avec respect et enthousiasme.</p>
        <p>N'hésitez pas à me contacter pour un échange riche et stimulant où chaque instant est empreint de sensualité et de complicité. J'ai hâte de vous faire découvrir un univers où la magie de la conversation fait toute la différence. À très bientôt, je l'espère !</p>
      </>
  },
  {
    id: 6,
    name: "Julie",
    photo: "image(6)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle est attentive aux besoins et désirs de ses interlocuteurs, offrant une écoute active et une compréhension profonde pour adapter ses réponses aux fantasmes et préférences de chacun.",
    metaTitle: "Hôtesse de Téléphone Rose | Julie à Votre Écoute au 090340840",
    metaDescription: "Découvrez Julie, votre hôtesse de téléphone rose : conversations envoûtantes, moments personnalisés et pleine confidentialité pour une expérience inoubliable.",
    description:
      <>
        <p>Bienvenue sur mon profil ! Je suis votre hôtesse de téléphone rose, prête à vous offrir des moments d'évasion et de plaisir au téléphone. Avec une voix douce et envoûtante, je suis là pour écouter, discuter et partager des instants intimes qui éveilleront vos sens et nourriront vos fantasmes.</p>
        <p>Que vous cherchiez à explorer vos désirs les plus secrets ou simplement à vous détendre et à vous évader du quotidien, je suis à l'écoute et prête à répondre à vos besoins. Mon objectif est de vous offrir une évasion délicieuse, en veillant à ce que chaque échange soit plaisant et respectueux.</p>
        <p>N'hésitez pas à me contacter pour une expérience personnalisée et raffinée, où je m'engage à vous offrir un moment de plaisir et de complicité sur mesure. J'ai hâte de faire votre connaissance et de vous emmener dans un voyage sensoriel où tous vos désirs peuvent s'exprimer librement.</p>
      </>
  },
  {
    id: 7,
    name: "Laura",
    photo: "image(7)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle est imaginative et sait jouer avec les fantasmes et les scénarios pour offrir des expériences variées et captivantes.",
    metaTitle: "Éveillez Vos Sens avec Claire : Hôtesse de Téléphone Rose au 090340840",
    metaDescription: "Découvrez une expérience unique avec notre hôtesse de téléphone rose. Conversations captivantes, écoute attentive et moments de plaisir sur mesure, en toute confidentialité.",
    description:
      <>
        <p>Bonjour ! Je suis Laura, votre hôtesse de téléphone rose pour une escapade chaleureuse et pleine de complicité.</p>
        <p>je vous invite à explorer un monde de fantasmes et de découvertes sensuelles où chaque conversation est une aventure unique. Mon but est de vous offrir un moment de détente, d'écoute et de plaisir, tout en respectant vos désirs et vos limites. Que vous cherchiez des échanges coquins ou simplement une conversation stimulante, je suis là pour vous accompagner et éveiller vos sens.</p>
        <p>Ma priorité est de vous offrir une expérience qui soit aussi satisfaisante que mémorable, en m'adaptant à vos préférences et en étant à l’écoute de vos besoins. Si vous cherchez une partenaire pour égayer vos soirées ou simplement pour partager des moments de plaisir et de détente, je suis impatiente de faire votre connaissance.</p>
        <p>À très bientôt pour un échange plein de charme et de complicité !</p>
      </>
  },
  {
    id: 8,
    name: "Marion",
    photo: "image(8)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle possède une assurance naturelle qui transparaît dans sa manière de parler et d'interagir, ce qui contribue à créer une atmosphère de confiance et de confort.",
    metaTitle: "Hôtesse de Téléphone Rose : Évasion Sensuelle au 090340840",
    metaDescription: "Découvrez une expérience envoûtante avec notre hôtesse de téléphone rose. Conversations personnalisées, respect et confidentialité assurés pour des moments inoubliables.",
    description:
      <>
        <p>Bonjour ! Je suis Marion, votre hôtesse de téléphone rose dédiée à rendre vos moments les plus intimes et agréables.</p>
        <p>J'apprécie les échanges authentiques et les moments de complicité où chaque mot compte. J'aime découvrir ce qui vous fait vibrer, partager des fantasmes et explorer ensemble vos rêves les plus secrets. Ma passion pour la séduction subtile et mon sens de l'écoute me permettent de vous offrir une connexion unique, empreinte de chaleur et de sensualité. Chaque appel est une nouvelle aventure, et je suis impatiente de vous y emmener.</p>
        <p>Mon objectif est de vous offrir une expérience agréable et mémorable, où chaque interaction est une occasion de se libérer du quotidien et de se plonger dans un monde de plaisir et de complicité.</p>
        <p>N'hésitez pas à me contacter pour un moment de détente et de plaisir partagé. Je suis là pour vous, prête à faire de chaque appel une expérience inoubliable.</p>
      </>
  },
  {
    id: 9,
    name: "Olivia",
    photo: "image(9)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle respecte les limites et les préférences de ses interlocuteurs, en maintenant toujours un niveau élevé de professionnalisme et de confidentialité.",
    metaTitle: "Éveillez vos Fantasmes avec Olivia : Hôtesse de Téléphone au 090340840",
    metaDescription: "Découvrez une expérience unique avec notre hôtesse de téléphone rose : conversations envoûtantes, fantasmes éveillés et moments personnalisés dans le respect total.",
    description:
      <>
        <p>Bonjour, je suis Olivia, l'hôtesse qui saura vous emmener dans un univers de sensualité et de complicité. Avec un timbre de voix envoûtant et une écoute attentive, je suis là pour satisfaire vos désirs les plus secrets. Que vous ayez besoin de réconfort, de chaleur ou simplement de vous évader du quotidien, je suis prête à vous offrir des moments d’évasion et de plaisir sur mesure.</p>
        <p>J’adore créer des atmosphères intimes et personnalisées où vous vous sentirez totalement à l’aise. Ma passion pour les échanges authentiques et ma capacité à m’adapter à vos envies font de chaque conversation une expérience unique. Je suis également une amoureuse des mots et de la poésie, ce qui me permet d’ajouter une touche délicate et raffinée à chaque échange.</p>
        <p>En choisissant de discuter avec moi, vous trouverez une interlocutrice douce, attentive et imaginative. Je suis là pour vous offrir un moment de détente, où vos fantasmes peuvent prendre vie en toute sécurité. Que vous cherchiez à explorer vos rêves les plus audacieux ou simplement à passer un agréable moment, je suis à votre écoute, prête à vous accompagner dans cette aventure sensuelle.</p>
      </>
  },
  {
    id: 10,
    name: "Emma",
    photo: "image(10)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Sa capacité à improviser et à s'adapter à des situations variées rend chaque interaction unique et excitante.",
    metaTitle: "Conversation Privée avec Emma au 090340840",
    metaDescription: "Découvrez des conversations envoûtantes avec notre hôtesse de téléphone rose. Moments intimes, écoute attentive, et plaisir personnalisé garantis. Évadez-vous maintenant !",
    description:
      <>
        <p>Bonjour, je suis Emma, votre hôtesse de téléphone rose dédiée à rendre chaque appel spécial et mémorable. Avec une voix douce et envoûtante, je suis là pour vous offrir une expérience chaleureuse et personnalisée. Que vous cherchiez à discuter de vos fantasmes les plus secrets ou simplement à partager des moments d’intimité, je suis à votre écoute avec toute la passion et le respect que vous méritez.</p>
        <p>Je possède une grande expérience dans le domaine, ce qui me permet de créer des échanges authentiques et captivants. Mon objectif est de vous faire sentir unique et valorisé, en répondant à vos désirs avec une attention particulière. Que vous soyez en quête de jeux de rôle, de confidences ou de simples conversations agréables, je suis prête à explorer avec vous tous les aspects de votre imagination.</p>
        <p>La discrétion est ma priorité absolue ; chaque échange reste confidentiel et respectueux. Je suis là pour vous offrir un moment de détente et de plaisir, loin du quotidien. N'hésitez pas à me contacter pour partager des instants complices et redécouvrir le plaisir d'une conversation attentive et sincère. Au plaisir de faire votre connaissance et de créer ensemble des souvenirs inoubliables.</p>
      </>
  },
  {
    id: 11,
    name: "Louise",
    photo: "image(11)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle est ouverte à explorer divers fantasmes et préférences, offrant une expérience personnalisée à chaque appelant.",
    metaTitle: "Éveillez vos désirs avec Louise, hôtesse de téléphone rose au 090340840",
    metaDescription: "Découvrez Claire, votre hôtesse de téléphone rose. Conversations envoûtantes, fantasmes réalisés et discrétion assurée pour des moments intenses et mémorables.",
    description:
      <>
        <p>Bonjour ! Je suis Louise, votre hôtesse au service de vos désirs les plus secrets. Avec une voix douce et envoûtante, je suis là pour vous offrir une expérience personnalisée, où chaque conversation est une escapade dans un monde de sensualité et de complicité. Mon objectif est de vous écouter, de vous comprendre et de répondre à vos envies avec passion et discrétion.</p>
        <p>Derrière chaque appel, je mets toute ma créativité et mon professionnalisme pour vous garantir des moments de plaisir uniques. Que vous cherchiez à explorer vos fantasmes ou simplement à discuter de vos désirs, je m'adapte à vos besoins pour vous offrir une interaction sur mesure. Mon approche est empreinte de douceur et d'authenticité, vous permettant de vous sentir à l'aise et en confiance à chaque instant.</p>
        <p>Je suis disponible pour vous 24h/24, prête à vous transporter dans un univers où vos souhaits sont prioritaires. Avec moi, chaque appel devient une aventure sensorielle où vos rêves prennent vie, dans un cadre respectueux et sans jugement. Offrez-vous un moment de pure indulgence avec Sophie, votre complice des plaisirs exquis.</p>
      </>
  },
  {
    id: 12,
    name: "Alice",
    photo: "image(12)",
    alt: "Hôtesse de téléphone rose en lingerie",
    cardDescription: "Elle sait comment éveiller les sens et créer une atmosphère intimiste avec sa voix et ses mots.",
    metaTitle: "Hôtesse de Téléphone Rose : Moments Inoubliables au 090340840",
    metaDescription: "Découvrez des conversations envoûtantes avec notre hôtesse de téléphone rose. Moments sur mesure et plaisir garanti. Évadez-vous dès maintenant !",
    description:
      <>
        <p>Bienvenue dans un univers où chaque échange est une aventure passionnante ! Je suis Alice, votre hôtesse de téléphone rose dédiée à vous offrir une expérience chaleureuse et stimulante. Avec une voix douce et envoûtante, je suis ici pour éveiller vos fantasmes et répondre à vos désirs les plus secrets, le tout dans un cadre respectueux et confidentiel.</p>
        <p>Que vous recherchiez une conversation romantique, ludique ou plus osée, je m'adapte à vos envies et vous offre un moment de détente inégalé. Mon approche est entièrement personnalisée pour satisfaire vos besoins spécifiques, vous permettant de vous évader du quotidien et de plonger dans un monde de plaisir et d’excitation.</p>
        <p>La discrétion est au cœur de notre interaction. Vous pouvez partager vos désirs sans réserve, sachant que chaque échange est confidentiel et respectueux. Mon objectif est de vous faire vivre une expérience mémorable, où chaque mot compte et où votre satisfaction est ma priorité. Ensemble, nous créerons des moments inoubliables qui feront battre votre cœur plus fort.</p>
      </>
  }
]

export default hostessesArray;